<template>
  <div class="size">
    <div class="coinflip-result-animation" :class="result == 0 ? 'heads' : 'tails'"></div>
  </div>
</template>
<script>
export default {
  props: ["result"],
  created() {
    setTimeout(() => {
      window.socket.emit("GAME_ACTION", { action: "balance" });
      this.$emit("doneCoinflip");
    }, 2000);
  },
};
</script>
<style scoped>
.size {
  transform: scale(0.5);
}

.coinflip-result-animation {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200 !important;
  width: 248px;
  height: 248px;
  margin: 2em auto;
  background-position-x: 0;
  background-position-y: 0;
  background-repeat: no-repeat;
  animation: animate 2s steps(49);
  overflow: hidden;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/** devices screen size wrapper guide **/

/* @media (min-width: 320px) {
		.coinflip-result-animation {
			left: 10px;
		}
	}

	@media (min-width: 375px) {
		.coinflip-result-animation {
			left: 16px;
		}
	}

	@media (min-width: 414px) {
		.coinflip-result-animation {
			left: 20px;
		}
	}

	@media (min-width: 576px) {
		.coinflip-result-animation {
			left: 48px;
		}
	}

	@media (min-width: 768px) {
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	} */

/** END - devices screen size wrapper guide **/
.heads {
  background-image: url("/sprite/Flip1.png");
}

.heads1 {
  background-image: url("/sprite/Flip3.png");
}

.tails {
  background-image: url("/sprite/Flip2.png");
}

.tails1 {
  background-image: url("/sprite/Flip4.png");
}

@keyframes animate {
  0% {
    background-position: 0 -248px;
    transition-timing-function: ease;
  }

  100% {
    background-position: 0 -12400px;
    transition-timing-function: ease;
  }
}
</style>
