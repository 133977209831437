<template>
  <div class="create-game-bg-color">
    <error-dialog :dialog="hasError" :message="error"></error-dialog>

    <v-dialog v-model="createDialog" max-width="450" class="dialog-container-v1">
      <v-card>
        <v-card-title class="headline font-weight-bold">
          <span class="mx-auto main-color">Create Game Confirmation</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="text-center pa-4">

          <div class="pa-2">
            Proceed creating {{ numberOfGames }} CoinFlip game(s) with each flip bet amounting
            {{ betAmount | currency("") }} each
          </div>
          <div class="main-color">
            <i> Do you want to proceed?</i>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="#A94A4A" outlined small @click="createDialog = false">
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green" small @click="submitCreateGames" :loading="loading">
            Yes, proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="800" class="dialog-1" content-class="elevation-0">
      <template v-slot:activator="{ on, attrs }">
        <v-btn width="100%" class="coinflip-btn-create-game  no-uppercase caption pa-3 pt-1 pb-1 h-5" elevation="0"
          v-bind="attrs" v-on="on">
          <v-icon small>mdi-plus</v-icon>
          <span class="font-bold">Create Game</span>
        </v-btn>
      </template>

      <v-card elevation="0" color="#1B2221">
        <v-form ref="formCreate">
          <v-row class="ma-2">
            <v-col cols="12" class="create-game-padding mobile-padding-bottom">
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="5" xl="5"
                  class="create-game-padding1 mobile-padding-bottom pb-0">
                  <v-row>
                    <v-col cols="1" xs="1" sm="1" md="2" lg="2" xl="2" class="pl-5 number-label">
                      <h1 style="color: #2CAB62;">1</h1>
                    </v-col>
                    <v-col cols="8" class="pl-lg-6">
                      <small class="caption mr-md-2 mr-lg-2 text-title">Choose a side</small>
                      <v-btn-toggle v-model="sidePicked" color="primary" group mandatory>
                        <v-btn :value="0" outlined class="btn-coin mt-0 ml-0">
                          <v-avatar color="primary" size="25">
                            <img :src="headsImage" />
                          </v-avatar>
                          <span class="ml-2 caption mt-0">HEADS</span>
                        </v-btn>
                        <v-btn :value="1" outlined class="btn-coin mt-0">
                          <v-avatar color="primary" size="25">
                            <img :src="tailsImage" />
                          </v-avatar>
                          <span class="ml-2 caption">TAILS</span>
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6"
                  class="py-0 my-0 mt-md-3 mt-lg-3 mt-xl-3 create-game-padding1">
                  <v-row>
                    <v-col cols="1" xs="12" sm="2" md="2" lg="2" xl="2" class="number-label mobile-padding-bottom">
                      <h1 style="color: #2CAB62;">2</h1>
                    </v-col>
                    <v-col cols="8" class="mobile-padding-bottom">
                      <small class="caption  text-title">No. of Games</small>
                      <v-select custom-select-class :items="dropdown_edit" outlined item-value="value"
                        v-model="numberOfGames" small id="test"
                        class="num-games-dropdown dropdown-games"></v-select></v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12"
                  class="py-0 pr-0 mr-0 create-game-padding1 mobile-padding-bottom">
                  <v-row>
                    <v-col cols="1" xs="12" sm="2" md="2" lg="1" xl="1" class="number-label mobile-padding-bottom py-0">
                      <h1 style="color: #2CAB62;">3</h1>
                    </v-col>
                    <v-col cols="9" xs="10" sm="10" md="8" lg="11" xl="11" class="pl-2 mobile-padding-bottom py-0">
                      <small class="caption text-title">Bet Amount</small>
                      <v-row>
                        <v-col cols="12" xs="12" sm="3" md="2" lg="2" xl="2" class="pl-0 pb-0 pt-1 mobile-padding-bottom">
                          <v-text-field hide-details outlined class="text-field bet-amount-textfield" type="text"
                            v-model="betAmount">
                          </v-text-field>
                        </v-col>
                        <v-col cols="10" xs="12" sm="9" md="10" lg="10" xl="10"
                          class="d-none d-xl-flex d-lg-flex d-md-flex  d-sm-flex">
                          <v-btn class="pr-1 ml-2 pl-0" outlined color="default" large @click="setBetMin">
                            min
                          </v-btn>
                          <!-- <v-btn class="d-none d-md-block ma-1 mb-3" small @click="setBetHalf">
								1/2
							</v-btn> -->
                          <v-btn class="pr-1 ml-2 pl-0" outlined color="default" large @click="setBetDouble">
                            x2
                          </v-btn>
                          <!-- <v-btn class="d-none d-md-block ma-1 mb-3" small @click="setBetAmount(100)">
								100
							</v-btn>
							<v-btn class="d-none d-md-block ma-1 mb-3" small @click="setBetAmount(200)">
								200
							</v-btn>
							<v-btn class="d-none d-md-block ma-1 mb-3" small @click="setBetAmount(500)">
								500
							</v-btn>
							<v-btn class="d-none d-md-block ma-1 mb-3" small @click="setBetAmount(1000)">
								1000
							</v-btn> -->
                          <v-btn class="pr-1 ml-2 pl-0" outlined color="default" large @click="setBetAmountIncrease(100)">
                            +100
                          </v-btn>
                          <v-btn class="pr-1 ml-2 pl-0" outlined color="default" large @click="setBetAmountIncrease(500)">
                            +500
                          </v-btn>
                          <v-btn class="pr-1 ml-2 pl-0" outlined color="default" large
                            @click="setBetAmountIncrease(1000)">
                            +1000
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-center pb-0 pt-0">
                  <div class="d-sm-none">
                    <v-btn class="ma-1 mb-3" outlined color="default" small @click="setBetMin">
                      min
                    </v-btn>
                    <v-btn class="ma-1 mb-3" outlined color="default" small @click="setBetDouble">
                      x2
                    </v-btn>
                    <!-- <v-btn class="ma-1 mb-3" outlined color="default" small @click="setBetAmount(100)">
							100
						</v-btn> -->
                    <v-btn class="ma-1 mb-3" outlined color="default" small @click="setBetAmountIncrease(100)">
                      +100
                    </v-btn>
                    <v-btn class="ma-1 mb-3" outlined color="default" small @click="setBetAmountIncrease(500)">
                      +500
                    </v-btn>
                    <v-btn class="ma-1 mb-3" outlined color="default" small @click="setBetAmountIncrease(1000)">
                      +1000
                    </v-btn>
                  </div>
                </v-col>
                <v-col class="d-flex justify-end">
                  <v-btn @click="dialog = false" outlined color="#A94A4A" class="no-uppercase caption pa-3 pt-1 pb-1 h-5"
                    elevation="0">
                    <span class="font-bold">Cancel</span>
                  </v-btn>
                  <v-btn @click="createDialog = true"
                    class="coinflip-btn-create-game  no-uppercase caption pa-3 pt-1 pb-1 h-5 ml-3" elevation="0">
                    <v-icon small>mdi-plus</v-icon>
                    <span class="font-bold">{{ createGameText }}</span>
                  </v-btn>
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import logo from "../../assets/logo.png";
import headsImage from "../../assets/images/heads/heads_25x.png";
import tailsImage from "../../assets/images/tails/tails_25x.png";
import ErrorDialog from "../dialogs/ErrorDialog";
export default {
  components: {
    ErrorDialog,
  },
  data() {
    return {
      dialog: false,
      dropdown_font: [{ text: "Arial" }, { text: "Calibri" }, { text: "Courier" }, { text: "Verdana" }],
      dropdown_edit: [
        { text: "1 Game", value: 1 },
        { text: "2 Games", value: 2 },
        { text: "3 Games", value: 3 },
        { text: "5 Games", value: 5 },
        { text: "10 Games", value: 10 },
      ],
      sidePicked: 0,
      numberOfGames: 1,
      betAmount: 20,
      headsImage: headsImage,
      tailsImage: tailsImage,
      logoImage: logo,
      lobbyType: "flip",
      rules: {
        required: value => !!value || "Required.",
        minBet: value => parseFloat(value) >= 20 || "Mininum bet amount is 20.00",
      },
      createDialog: false,
    };
  },
  computed: {
    ...mapState("lobby", ["loading", "error", "hasError", "lobbyFlipFee"]),
    ...mapState("player", ["player"]),
    ...mapState("auth", ["user"]),
    createGameText: function () {
      return this.numberOfGames == 1 ? `Create Game` : `Create ${this.numberOfGames} Games`;
    },
  },
  methods: {
    submitCreateGames: async function () {
      this.createDialog = false;
      const checkHasEnoughCredits = this.checkHasEnoughCredits();
      const isFormValid = this.isFormValid();
      if (isFormValid && checkHasEnoughCredits) {
        //bulk create lobbies
        let lobbies = [];

        for (let count = 0; count < this.numberOfGames; count++) {
          const temp = {
            bets: [
              {
                amount: this.betAmount,
                sideIndex: this.sidePicked, //could be 0 or 1; 0 = heads; 1 = tails
              },
            ],
            lobbyType: this.lobbyType,
            betAmount: this.betAmount,
          };
          lobbies.push(temp);
        }

        const payload = {
          lobbies: lobbies,
          key: this.$route.params.key,
        };
        const createdLobbies = await this.$store.dispatch("lobby/bulkCreateLobbies", payload);

        // console.log('createdLobbies: ', createdLobbies);

        return createdLobbies;
      }
    },
    checkHasEnoughCredits: function () {
      return true;
    },
    clearFormFields: function () {
      this.$refs.formCreate.reset();
    },
    isFormValid: function () {
      return this.$refs.formCreate.validate();
    },

    setBetHalf: function () {
      this.betAmount = this.betAmount > 0 ? parseFloat(this.betAmount / 2).toFixed(2) : 0;
    },

    setBetDouble: function () {
      this.betAmount = this.betAmount > 0 ? this.betAmount * 2 : 0;
    },

    setBetMin: function () {
      this.betAmount = 20;
    },

    setBetAmount: function (amount) {
      this.betAmount = amount;
    },

    setBetAmountIncrease: function (amount) {
      this.betAmount = this.betAmount > 0 ? parseFloat(this.betAmount) + parseFloat(amount) : amount;
    },

    setBetAmountAllIn: function () {
      this.betAmount = this.wallet;
    },
  },
};
</script>

<style scoped>
.dialog-1 {
  box-shadow: none !important;
}

.main-color {
  color: #F18701 !important;
}

.coinflip-btn-create-game {
  background: linear-gradient(180deg, #ed862c 0%, #ee6540 100%);
  border-radius: 5px;

}

.no-uppercase {
  text-transform: unset !important;

}

.font-bold {
  font-weight: 700;
  font-size: 14px;
}


.bet-amount-textfield {
  width: 180px !important;
}


.number-label {
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-amount label {
  left: 0px !important;
}

.text-title {
  color: #2cab62 !important;
}

.text-field {
  width: 90%;
}

.num-games-dropdown>>>fieldset {
  color: white !important;
}

.bet-amount-textfield>>>fieldset {
  color: white !important;
}

.btn-coin {
  border: 1px solid !important;
  color: white !important;
  border-radius: 5px !important;
}

.dialog-container-v1 {
  background-color: #1b2221;
}

@media screen and (max-width: 425px) {
  .create-game-bg-color {
    background: none;
  }

  .create-game-padding {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobile-padding-bottom {
    padding-bottom: 0px;
    padding-top: 0px;
  }
}

@media screen and (max-width: 375px) {
  .create-game-padding1 {
    padding-left: 0px;
  }

  .mobile-padding-bottom {
    padding-bottom: 0px;
    padding-top: 0px;
  }
}
</style>
