<template>
  <v-card class="my-2 game-card-bg-color" ref="gameCard">
    <v-dialog v-model="cancelDialog" max-width="350">
      <v-card>
        <v-card-title class="headline font-weight-bold">
          <span class="mx-auto modal-main-text">Cancel Coinflip</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="text-center pa-4">
          <div class="headline">
            Do you want to proceed?
          </div>
          <div class="pa-2">Cancel Coinflip #{{ coinflip.sequenceId }}</div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="warning" outlined small @click="cancelDialog = false">
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" small @click="cancelCoinflip">
            Yes, proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="joinDialog" max-width="450">
      <v-card>
        <v-card-title class="headline font-weight-bold">
          <span class="mx-auto">Join Coinflip</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="text-center pa-4">
          <div class="headline">
            Do you want to join?
          </div>
          <div class="ma-3">
            <strong class="text-h5">
              #{{ coinflip.sequenceId }}
            </strong>
          </div>
          <div class="d-flex align-center justify-center">
            <div class="mr-3">
              Amount:
            </div>
            <div>
              <v-img :src="betA.sideIndex == 0 ? tailsImage : headsImage" width="20" class="mr-1"></v-img>
            </div>
            <span> <span class="caption">&nbsp;{{ betA.amount | currency("") }}</span></span>
          </div>
          <div class="d-flex justify-end mt-5">
            <v-checkbox dense class="shrink ma-1 pa-1" hide-details="true" v-model="dontShowJoinModal"
              label="Don't show this again"></v-checkbox>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="warning" text small @click="joinDialog = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green" text small :disabled="joiningLobbies.includes(coinflip._id) || betA.amount > wallet"
            @click="joinGame({
              _id: coinflip._id,
              sideIndex: betA.sideIndex == 0 ? 1 : 0,
            })">
            Join
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="text-center lobby-card mx-md-1 mx-lg-1 mx-xl-1" :class="{ joined: joined }">

      <v-col cols="5" class="pa-3 pb-1" :class="`${grayOutLoser && lobbyWinner != betA.sideIndex ? 'loser' : ''}`">
        <v-sheet :class="`game-card-user-color ${betA.player._id == user.id ? 'current-user' : ''}`" min-height="130">
          <div class="avatar-container ma-auto">
            <Avatar :player="betA.player" v-if="betA.player.avatar_type == 'avatar' || !!!betA.player.avatar_type">
            </Avatar>

            <v-avatar v-if="betA.player.avatar_type == 'photo'" class="mt-3">
              <img :src="betA.player.display_photo.thumb" />
            </v-avatar>
          </div>
          <div class="mt-1" v-if="betA && betA.player">
            <small class="player-name ">{{ betA.player.display_name | truncate(12) }}</small>
          </div>
          <div class="pb-2">
            <v-chip label class="chip-color">
              <v-img :src="betA.sideIndex == 0 ? headsImage : tailsImage" class="mr-1"></v-img>
              <div>
                <div :class="`amount-label ${grayOutLoser ? lobbyWinner == betA.sideIndex ? 'winnings' : 'losses' : ''}`">
                  {{ grayOutLoser && lobbyWinner == betA.sideIndex ? winningAmount : betA.amount | currency("") }}
                </div>
              </div>
            </v-chip>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="2" class="d-flex align-center pa-3 pb-1">
        <div class="vs-container text-center">
          <v-img max-width="20" class="sword-open-games-margin" :src="fencing"
            v-if="!coinflip.startAnimation && !isPrevious"></v-img>
          <div v-else-if="coinflip.startAnimation && countDown != -1 && !isPrevious" class="blink">{{ countDown }}</div>
          <div class="cf-animation-container" v-else>
            <AnimateFlipResult :result="lobbyWinner" v-if="lobbyWinner != null" @doneCoinflip="doneCoinflipUpdate" />
          </div>
        </div>
      </v-col>
      <v-col cols="5" class="pa-3 pb-1"
        :class="`${grayOutLoser && betB && lobbyWinner != betB.sideIndex ? 'loser' : ''}`">
        <v-sheet class="game-card-user-color" min-height="130" v-if="!!betB">
          <div class="avatar-container ma-auto">
            <Avatar :player="betB.player" v-if="betB.player.avatar_type == 'avatar' || !!!betB.player.avatar_type">
            </Avatar>
            <v-avatar v-if="betB.player.avatar_type == 'photo'" class="mt-3">
              <img :src="betB.player.display_photo.thumb" />
            </v-avatar>
          </div>
          <div class="mt-1" v-if="betB && betB.player">
            <small class="player-name">{{ betB.player.display_name | truncate(12) }}</small>
          </div>
          <div class="pb-2">
            <v-chip label class="chip-color">
              <v-img :src="betB.sideIndex == 0 ? headsImage : tailsImage" class="mr-1"></v-img>
              <div :class="`amount-label ${grayOutLoser ? lobbyWinner == betB.sideIndex ? 'winnings' : 'losses' : ''}`">
                {{ grayOutLoser && lobbyWinner == betB.sideIndex ? winningAmount : betB.amount | currency("") }}
              </div>
            </v-chip>
          </div>
        </v-sheet>
        <!-- if no other player & player owned/created game, allow cancelling of game -->
        <v-sheet class="game-card-user-color" min-height="150" v-if="owned && coinflip.status == 0">
          <div class="pt-5">
            <div class="ma-auto">
              <p class="blue-grey--text text--lighten-1 caption">Waiting for opponent...</p>
            </div>
            <div class="mt-1">
              <v-btn color="grey darken-4" elevation="2" tile x-small @click="cancelDialog = true">Cancel</v-btn>
            </div>
          </div>
        </v-sheet>
        <!-- game is cancelled -->
        <v-sheet min-height="130" v-else-if="owned && coinflip.status == -1"
          class="d-flex align-center game-card-user-color">
          <div class="mx-auto caption">
            <div class="danger d-flex align-center">
              <v-icon left color="#EE6540" class="mr-1">mdi-cancel</v-icon>
              <div class="mt-1">Cancelled</div>
            </div>
          </div>
        </v-sheet>
        <!-- if no other player, allow join game -->
        <v-sheet class="game-card-user-color" min-height="130" v-else-if="!owned && coinflip.status == 0">
          <div class="pt-5">
            <div class="join-container" v-if="coinflip.status == 0">
              <v-btn medium outlined color="orange" class="mx-auto caption" @click="JoinCoinflip"
                :disabled="joiningLobbies.includes(coinflip._id) || betA.amount > wallet">{{
                  joiningLobbies.includes(coinflip._id) ? "Joining..." : "JOIN" }}</v-btn>
            </div>
            <div class="mt-2">

            </div>
            <div class="pb-2">
              <v-chip label class="chip-color">
                <v-img :src="betA.sideIndex == 0 ? tailsImage : headsImage" class="mr-1"></v-img>
                <div class="amount-label">{{ betA.amount | currency("") }}</div>
              </v-chip>
            </div>
          </div>
        </v-sheet>
        <!-- game is cancelled -->
        <v-sheet min-height="150" v-else-if="!owned && coinflip.status == -1"
          class="d-flex align-center game-card-bg-color">
          <div class="mx-auto caption">
            <div class="danger d-flex align-center">
              <v-icon left color="#EE6540" class="mr-1">mdi-cancel</v-icon>
              <div class="mt-1">Cancelled</div>
            </div>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="12" class="pa-0 pb-2 d-flex justify-space-between px-3">
        <div>
          <v-btn @click="showProvablyFair = true" text x-small
            class="blue-grey--text text--lighten-1 caption lowercase">Provably Fair</v-btn>
        </div>
        <div>
          <small class="blue-grey--text text--lighten-1 caption">Coinflip ID: #{{ coinflip.sequenceId }}</small>
        </div>
      </v-col>
    </v-row>

    <div v-if="showProvablyFair" class="provably-container">
      <v-btn icon link class="close" @click="showProvablyFair = false" small><v-icon>mdi-minus</v-icon></v-btn>
      <div v-if="coinflip.status == 0" class="text-sm my-5 px-10">
        Private Seed: <span class="font-weight-bold">{{ coinflip.provablyFair.serverSeedHash }}</span>
      </div>

      <div v-else>
        <div :class="`${$vuetify.breakpoint.xsOnly ? 'mtext-sm' : 'text-sm'} my-5 px-10`">
          <div>
            Private Seed:
          </div> <span class="font-weight-bold">{{ getServerSeeders }}</span>
        </div>
        <div :class="`${$vuetify.breakpoint.xsOnly ? 'mtext-sm' : 'text-sm'} my-5 px-10`">
          <div>
            Public Seed:
          </div> <span class="font-weight-bold">{{ getPublicSeeder }}</span>
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import Avatar from "../Avatar.vue";
import fencing from "../../assets/images/icon/fencing.svg";
import logo from "../../assets/logo.png";
import headsImage from "../../assets/images/heads/heads_25x.png";
import tailsImage from "../../assets/images/tails/tails_25x.png";
import AnimateFlipResult from "./AnimateFlipResult";

export default {
  components: {
    Avatar,
    AnimateFlipResult,
  },
  props: ["coinflip", "owned", "isPrevious"],
  data: () => ({
    showProvablyFair: false,
    logo: logo,
    fencing: fencing,
    headsImage: headsImage,
    tailsImage: tailsImage,
    joiningLobbies: [],
    countDown: 5,
    lobbyWinner: null,
    isCountingDown: false,
    winningAmount: 0,
    avatarOptions: {
      circleColor: "#a7c5b4",
      accessoriesType: "Blank",
      clotheType: "ShirtVNeck",
      clotheColor: "White",
      eyebrowType: "DefaultNatural",
      eyeType: "Default",
      facialHairColor: "Black",
      facialHairType: "Blank",
      hairColor: "Black",
      mouthType: "Default",
      skinColor: "Pale",
      topType: "NoHair",
      topColor: "Black",
    },
    avatarOptions1: {},
    avatarOptions2: {},
    cancelDialog: false,
    grayOutLoser: false,
    joinDialog: false,
    dontShowJoinModal: false
  }),
  watch: {
    coinflip: function (newVal) {
      if (newVal.status == -1) {
        this.hideGameCardParent();
        return;
      }

      if (!!newVal.startAnimation == true && !this.isCountingDown) {
        this.isCountingDown = true;
        this.countDownTimer();
        setTimeout(() => {
          this.hideGameCardParent();
        }, 15000);
      }
    },

    betB: function (newVal) {
      if (!!newVal == true) {
        if (!!this.betB == true && !!this.betB.player == true && !!this.betB.player.avatar_options == true) {
          this.avatarOptions2 = this.betB.player.avatar_options;
        }
      }
    },
    dontShowJoinModalCookie: function (newVal) {
      this.dontShowJoinModal = newVal;
    },
  },
  computed: {
    ...mapState("auth", ["user", "wallet"]),
    getServerSeeders() {
      if (this.coinflip.provablyFair && this.coinflip.provablyFair.serverSeedHash) {
        return this.coinflip.provablyFair.serverSeedHash;
      } return null;
    },
    getPublicSeeder() {
      if (this.coinflip.provablyFair && this.coinflip.provablyFair.publicSeedHash) {
        return this.coinflip.provablyFair.publicSeedHash;
      } return null;
    },
    audioUrl: function () {
      return require('../../assets/sound/flipcoin.mp3')
    },
    dontShowJoinModalCookie: function () {
      return this.$cookies.get('dontShowJoinModal') || false;
    },

    betA: function () {
      return !!this.coinflip == true ? this.coinflip.bets[0] : null;
    },
    betB: function () {
      return !!this.coinflip == true ? this.coinflip.bets[1] : null;
    },
    joined: function () {
      return this.coinflip.players.some((player) => player.id == this.user.id);
    },
  },
  methods: {
    countDownTimer() {
      if (this.countDown >= 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        (new Audio(this.audioUrl)).play();
        this.showLobbyWinner();
      }
    },
    showLobbyWinner() {
      this.lobbyWinner = this.coinflip.winningIndex;
    },
    JoinCoinflip() {
      if (this.dontShowJoinModalCookie) {
        this.joinGame({
          _id: this.coinflip._id,
          sideIndex: this.betA.sideIndex == 0 ? 1 : 0,
        });
      } else {
        this.joinDialog = true;
      }

    },
    joinGame: async function ({ _id, sideIndex }) {

      if (this.betA.amount <= this.wallet) {
        //push to joiningLobbies -> to disable join button; avoid double join
        this.joiningLobbies.push(_id);
        // const newWallet = parseFloat(this.wallet - this.betA.amount);

        // this.$store.dispatch("auth/setWallet", newWallet);

        const joined = await this.$store.dispatch("lobby/joinFlipLobby", {
          _id: _id,
          sideIndex,
          key: this.$route.params.key,
        });

        if (!!joined == true) {
          console.log("Joined lobby: ", joined);
          if (this.dontShowJoinModal) {
            this.$cookies.set('dontShowJoinModal', true, null, null, null, true);
          }
          this.joinDialog = false;
        } else {
          this.joiningLobbies = this.joiningLobbies.filter((id) => id != _id);
        }
      } else {
        return false;
      }
    },
    hideGameCardParent() {
      // this.$refs.gameCard.$el.parentElement.classList.add("settled");
      setTimeout(() => {
        //DEPRECATED
        // this.$refs.gameCard.$el.parentElement.remove();
        const id = this.$refs.gameCard.$el.parentElement.id;
        this.$store.commit("lobby/REMOVE_LOBBY_FROM_LOBBIES", id);
        this.$emit("removeGameCard", id)
        const defaultData = this.$options.data.call(this);
        Object.keys(defaultData).forEach(key => {
          this[key] = defaultData[key];
        });
      }, 2500);
    },

    cancelCoinflip: async function () {
      this.cancelDialog = false;
      await this.$store.dispatch("lobby/cancelFlipLobby", {
        id: this.coinflip._id,
        key: this.$route.params.key,
      });
    },

    doneCoinflipUpdate: function () {
      this.grayOutLoser = true;
      const totalBettedAmount = this.coinflip.bets.reduce(
        (sum, bet) => parseFloat(sum) + parseFloat(bet.amount),
        0
      );

      const flipTaxMultiplier = parseFloat(this.coinflip.lobbyFee.multiplier);
      this.winningAmount = parseFloat(totalBettedAmount - (totalBettedAmount * flipTaxMultiplier));

      //DEPRECATED - no longer needed
      // if (this.coinflip.winners.length > 0) {
      //   this.coinflip.winners.forEach((winner) => {
      //     if (this.user.id == winner.player._id) {
      //       const totalBettedAmount = this.coinflip.bets.reduce(
      //         (sum, bet) => parseFloat(sum) + parseFloat(bet.amount),
      //         0
      //       );
      //       const flipTax = parseFloat(this.coinflip.lobbyFee.percentage);
      //       const flipTaxMultiplier = parseFloat(100 - flipTax) / 100;

      //       const newWallet = parseFloat(this.wallet + totalBettedAmount * flipTaxMultiplier);
      //       this.$store.dispatch("auth/setWallet", newWallet);
      //     }
      //   });
      // }
    },
  },
  created() {
    this.dontShowJoinModal = this.dontShowJoinModalCookie;

    if (!!this.betA == true && !!this.betA.player == true && !!this.betA.player.avatar_options == true) {
      this.avatarOptions1 = this.betA.player.avatar_options;
    } else {
      this.avatarOptions1 = this.avatarOptions;
    }

    if (!!this.betB == true && !!this.betB.player == true && !!this.betB.player.avatar_options == true) {
      this.avatarOptions2 = this.betB.player.avatar_options;
    } else {
      this.avatarOptions2 = this.avatarOptions;
    }

    if (this.isPrevious && !!this.coinflip == true) {
      console.log("COUNT FLIP", this.coinflip)
      this.showLobbyWinner();
    }
  },
};
</script>

<style scoped>
.provably-container {
  background: #000d;
  position: absolute;
  inset: 0;
  padding: 10px;
  text-align: center;
}

.provably-container .close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.provably-container .mtext-sm {
  font-size: 10px;
  line-height: .9rem;
}

.provably-container .text-sm {
  font-size: 12px;
}

.modal-main-text {
  color: #F18701;
}

.current-user {
  border: 1px solid #EE6540;
}

.winnings {
  color: #02CE4D;
}

.losses {
  color: #EE6540;
}

.losses::before {
  content: "-";
}

.winnings::before {
  content: "+";
}

.amount-label {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.1;
  margin-left: 1px;
}

.danger {
  color: #EE6540;
}

.lowercase {
  text-transform: none;
}

.sword-open-games-margin {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.game-card-user-color {
  background: #1B2221;
  border-radius: 16px;
  padding: 15px 5px 5px 5px;
}

.game-card-bg-color {
  background: #161E1D;
  border-radius: 16px;
}

.chip-color {
  background: transparent !important;
}

.vs-container {
  width: 100%;
}

.avatar-container {
  width: 60px;
  height: 60px;
}

.join-container {
  height: 60px;
}

.player-name {
  font-size: 12px;
  font-family: sans-serif;
}

.joined {
  border: 1px solid #ff9800;
}

.loser {
  opacity: 0.3;
}

.cf-animation-container {
  position: relative;
}

.blink {
  /* -webkit-animation: blink 1.02s ease infinite;
  -moz-animation: blink 1.02s ease infinite; */
  animation: blink 1.02s ease infinite;
  font-weight: bold;
}


@keyframes blink {
  0% {
    color: #ff9800;
    scale: 5;
  }

  100% {
    color: white;
    scale: 1.5;
  }
}

/* @-moz-animation blink {
  0% {
    color: #ff9800;
    scale: 5;
  }

  100% {
    color: white;
    scale: 2;
  }
}

@-webkit-keyframes blink {
  0% {
    color: #ff9800;
    scale: 5;
  }

  100% {
    color: white;
    scale: 2;
  }
} */
</style>
