<template>
	<div class="text-center">
		<v-dialog v-model="showDialog" width="350">
			<v-card>
				<div class="font-weight-bold  alert-text-title d-flex items-center justify-center">
					ALERT MESSAGE
				</div>
				<v-divider></v-divider>

				<v-card-text class="my-5 mb-0">
					<div class="d-flex  justify-center items-center"><v-icon large
							color="#F44336">mdi-close-circle-outline</v-icon></div>
					<div class="text-center color-red">{{ title ? title : 'ERROR' }}</div>
				</v-card-text>

				<v-card-text class="text-center message mb-5">
					{{ message }}
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions class="d-flex justify-center">
					<v-btn color="#A94A4A" small @click="dialogDismissed = true">
						{{ closeBtnText ? closeBtnText : 'Close' }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
export default {
	props: ['dialog', 'message', 'title', 'closeBtnText'],
	data: () => ({
		dialogDismissed: false
	}),
	watch: {
		dialog: function () {
			this.dialogDismissed = !this.dialog;
		}
	},
	computed: {
		showDialog: function () {
			return this.dialog && !this.dialogDismissed;
		}
	}
};
</script>

<style>
.alert-text-title {
	color: #F18701 !important;
	font-weight: 700;
	font-size: 1.3rem;
	padding-bottom: 10px;
	padding-top: 10px;
	text-align: center !important;
}

.color-red {
	color: #F44336 !important;
	font-weight: 700;
}

.message {
	font-size: 1.1rem;
	color: white;
}
</style>