<template>
    <span>
        <avataaars :accessoriesType="avatar.accessoriesType" :clotheType="avatar.clotheType"
            :clotheColor="avatar.clotheColor" :eyebrowType="avatar.eyebrowType" :eyeType="avatar.eyeType"
            :facialHairType="avatar.facialHairType" :facialHairColor="avatar.facialHairColor"
            :graphicType="avatar.graphicType" :hairColor="avatar.hairColor" :mouthType="avatar.mouthType"
            :skinColor="avatar.skinColor" :topType="avatar.topType" :topColor="avatar.topColor"
            :avatarStyle="avatar.avatarStyle"></avataaars>
    </span>
</template>
<script>
import Avataaars from 'vuejs-avataaars'
export default {
    props: ['player'],
    components: {
        Avataaars
    },
    computed: {
        avatar() {
            // generate random avatar base on player id
            const playerId = Number(this.player._id)
            return {
                avatarStyle: 'Circle',
                topType: this.topTypes[playerId % this.topTypes.length],
                accessoriesType: this.accessoriesTypes[playerId % this.accessoriesTypes.length],
                hairColor: this.hairColors[playerId % this.hairColors.length],
                facialHairType: this.facialHairTypes[playerId % this.facialHairTypes.length],
                facialHairColor: this.facialHairColors[playerId % this.facialHairColors.length],
                clotheType: this.clotheTypes[playerId % this.clotheTypes.length],
                clotheColor: this.clotheColors[playerId % this.clotheColors.length],
                eyeType: this.eyeTypes[playerId % this.eyeTypes.length],
                eyebrowType: this.eyebrowTypes[playerId % this.eyebrowTypes.length],
                mouthType: this.mouthTypes[playerId % this.mouthTypes.length],
                skinColor: this.skinColors[playerId % this.skinColors.length],
                graphicType: this.graphicTypes[playerId % this.graphicTypes.length],
                topColor: this.topColors[playerId % this.topColors.length]
            }
        }
    },
    data() {
        return {
            accessoriesTypes: [
                'Blank',
                'Kurt',
                'Prescription01',
                'Prescription02',
                'Round',
                'Sunglasses',
                'Wayfarers'
            ],
            clotheTypes: [
                'BlazerShirt',
                'BlazerSweater',
                'CollarSweater',
                'GraphicShirt',
                'Hoodie',
                'Overall',
                'ShirtCrewNeck',
                'ShirtScoopNeck',
                'ShirtVNeck'
            ],
            clotheColors: [
                'Black',
                'Blue01',
                'Blue02',
                'Blue03',
                'Gray01',
                'Gray02',
                'Heather',
                'PastelBlue',
                'PastelGreen',
                'PastelOrange',
                'PastelRed',
                'PastelYellow',
                'Pink',
                'Red',
                'White'
            ],
            eyebrowTypes: [
                'Angry',
                'AngryNatural',
                'Default',
                'DefaultNatural',
                'FlatNatural',
                'RaisedExcited',
                'RaisedExcitedNatural',
                'SadConcerned',
                'SadConcernedNatural',
                'UnibrowNatural',
                'UpDown',
                'UpDownNatural'
            ],
            eyeTypes: [
                'Close',
                'Cry',
                'Default',
                'Dizzy',
                'EyeRoll',
                'Happy',
                'Hearts',
                'Side',
                'Squint',
                'Surprised',
                'Wink',
                'WinkWacky'
            ],
            facialHairColors: [
                'Auburn',
                'Black',
                'Blonde',
                'BlondeGolden',
                'Brown',
                'BrownDark',
                'Platinum',
                'Red'
            ],
            facialHairTypes: [
                'Blank',
                'BeardMedium',
                'BeardLight',
                'BeardMagestic',
                'MoustacheFancy',
                'MoustacheMagnum'
            ],
            graphicTypes: [
                'Bat',
                'Cumbia',
                'Deer',
                'Diamond',
                'Hola',
                'Pizza',
                'Resist',
                'Selena',
                'Bear',
                'SkullOutline',
                'Skull'
            ],
            hairColors: [
                'Auburn',
                'Black',
                'Blonde',
                'BlondeGolden',
                'Brown',
                'BrownDark',
                'PastelPink',
                'Platinum',
                'Red',
                'SilverGray'
            ],
            mouthTypes: [
                'Concerned',
                'Default',
                'Disbelief',
                'Eating',
                'Grimace',
                'Sad',
                'ScreamOpen',
                'Serious',
                'Smile',
                'Tongue',
                'Twinkle',
                'Vomit'
            ],
            skinColors: [
                'Tanned',
                'Yellow',
                'Pale',
                'Light',
                'Brown',
                'DarkBrown',
                'Black'
            ],
            topTypes: [
                'NoHair',
                'Eyepatch',
                'Hat',
                'Hijab',
                'Turban',
                'WinterHat1',
                'WinterHat2',
                'WinterHat3',
                'WinterHat4',
                'LongHairBigHair',
                'LongHairBob',
                'LongHairBun',
                'LongHairCurly',
                'LongHairCurvy',
                'LongHairDreads',
                'LongHairFrida',
                'LongHairFro',
                'LongHairFroBand',
                'LongHairNotTooLong',
                'LongHairShavedSides',
                'LongHairMiaWallace',
                'LongHairStraight',
                'LongHairStraight2',
                'LongHairStraightStrand',
                'ShortHairDreads01',
                'ShortHairDreads02',
                'ShortHairFrizzle',
                'ShortHairShaggyMullet',
                'ShortHairShortCurly',
                'ShortHairShortFlat',
                'ShortHairShortRound',
                'ShortHairShortWaved',
                'ShortHairSides',
                'ShortHairTheCaesar',
                'ShortHairTheCaesarSidePart'
            ],
            topColors: [
                'Black',
                'Blue01',
                'Blue02',
                'Blue03',
                'DarkBrown',
                'DarkGray',
                'Heather',
                'PastelBlue',
                'PastelGreen',
                'PastelOrange',
                'PastelRed',
                'PastelYellow',
                'Pink',
                'Red',
                'White'
            ]
        }
    }
}
</script>